<template>
  <div class="mt-3 w-100">
    <v-alert v-if="material.status === 0" color="error" text dense border="left">
      {{ $t('labels.goods_status_stop') }}
    </v-alert>

    <div class="text-center mb-2">
      {{ $t('labels.checked') }}:
      <span class="info--text text-h6">{{ item.receipted_quantity }}</span>
      <span class="text-h6">/</span>
      <span class="error--text text-h6">{{ item.request_quantity }}</span>
    </div>

    <v-row dense>
      <v-col cols="3">
        <v-btn color="primary" block style="height: 40px;" @click="printStamp">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="5">
        <v-text-field v-model.number="itemWeight" class="c-input-xs input-required" type="number"
          :label="$t('labels.weight_2')" dense outlined clearable hide-details min="1" ref="inputIndex_1"
          @keyup.enter="nextInputFocus(1)"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field v-model="itemUidConfirm" class="c-input-xs mb-3" type="text" label="UID" dense outlined clearable
          hide-details ref="inputIndex_2" @keyup.enter="nextInputFocus(2)"></v-text-field>
      </v-col>
    </v-row>

    <div class="d-flex align-center justify-space-between mb-2" v-if="material.process_exp_date">
      <div class="w-24">
        <v-autocomplete v-model="year" :items="yearOptions" class="c-input-xs" :label="$t('labels.expired_year')" dense
          outlined clearable hide-details ref="inputIndex_3" @change="nextInputFocus(3)"></v-autocomplete>
      </div>
      <div class="w-24">
        <v-autocomplete v-model="month" :items="monthOptions" class="c-input-xs" :label="$t('labels.expired_month')"
          dense outlined clearable hide-details :disabled="!year" ref="inputIndex_4"
          @change="nextInputFocus(4)"></v-autocomplete>
      </div>
      <div class="w-24">
        <v-autocomplete v-model="day" :items="dayOptions" :disabled="!month || !year" class="c-input-xs"
          :label="$t('labels.expired_day')" dense outlined clearable hide-details ref="inputIndex_5"
          @change="nextInputFocus(5)"></v-autocomplete>
      </div>
      <div class="w-24">
        <v-text-field v-model="lotTracking" class="c-input-xs" type="text" :label="$t('labels.lot')" dense outlined
          clearable hide-details ref="inputIndex_6" @keyup.enter="nextInputFocus(6)"></v-text-field>
      </div>
    </div>

    <div class="d-flex align-center justify-space-between">
      <div>
        <v-btn x-small color="success" class="mr-1" @click="confirm" v-if="isShowBtnConfirm">
          {{ $t('labels.confirm') }}
        </v-btn>
      </div>
      <div>
        <v-btn x-small color="error" @click="cancel">
          {{ $t('labels.cancel') }}
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBox v-if="dialogConfirmRemove" :title="$t('labels.remove_basket')"
        :description="$t('labels.scan_basket_and_confirm_to_remove')" :label="$t('labels.remove_basket_and_confirm')"
        :placeholder="$t('labels.remove_basket_and_confirm')" :codes="removeCodes" @cancel="cancelConfirmRemove"
        @confirm="confirmRemove" />
    </v-dialog>

  </div>
</template>

<script>
import checkMaterialMixins from "@/components/material/mixins/checkMaterialMixins";

export default {
  name: "CheckMaterial",
  components: {
    ConfirmRemoveBox: () => import('@/components/common/ConfirmRemoveBox')
  },
  mixins: [checkMaterialMixins]
}
</script>

<style scoped></style>
